footer.site-footer {
    .socials {
        margin-top: 2rem;
        .social-icons {
            max-width: 768px;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        a {
            display: block;
            width: 30px;
            padding: 0.3em 0;
            margin: 0 1rem;
        }
    }
}