.card-blocks {
    padding-top: 4rem;
    padding-bottom: 4rem;
    background-image: url(img/atg-pattern.jpg);
    background-size: contain;
}

.card-blocks {
    background-color: $brandMain;
    @include lessThanTablet() {
        padding: 2rem 1rem;
    }
    .flex-wrap {
        justify-content: space-between;
    }
    .card-icon {
        height: 120px;
        width: auto;
        aspect-ratio: 1/1;
        text-align: center;
        margin: auto;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin-bottom: 1.5rem;
    }
}

.card {
    background: white;
    max-width: 440px;
    border-radius: 3px;
    transition: all 0.5s ease;
    text-align: center;
    margin-top: 1rem;
    padding: 2rem;
    box-shadow: 10px 10px 0 1px $brandSecondary;
    @include lessThanTablet() {
        padding: 2rem 1rem;
        margin-bottom: 2rem !important;
    }
    &:hover {
        text-decoration: none;
        transform: scale(1.015);
    }
    >a {
        display: block;
        &:hover {
            text-decoration: none;
        }
        >* {
            padding: 1rem;
        }
        >img {
            padding: 0;
            margin-bottom: 1em;
        }
    }
    .card-title {
        font-size: 1.5em;
        margin: 1rem auto 1rem;
    }
    .separator {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }
    .didiv {
        &:before {
            background-color: white;
            border-color: white;
        }
    }
    p {
        position: relative;
        // padding: 0 1em;
        margin: 0 0;
    }
    .btn {
        padding: 0.6rem 1.8rem;
        margin: 0 0 1rem 1rem;
    }
}