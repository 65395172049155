.cursor-pointer {
    cursor: pointer;
}

.align-vertical {
    align-content: center;
    align-items: center;
}

.align-horizontal {
    justify-content: center;
}

.mobile-hide {
    @include lessThanTablet() {
        display: none;
    }
}

.mobile-only {
    display: none;
    @include lessThanTablet() {
        display: block;
    }
}