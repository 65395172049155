iframe,
img {
    max-width: 100%;
    height: auto;
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}

@mixin wide {
    @media (min-width: #{$wide-width}) {
        @content;
    }
}

@mixin lessThanDesktop {
    @media (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin lessThanTablet {
    @media (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: #{$mobile-width}) {
        @content;
    }
}