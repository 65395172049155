@charset "UTF-8";
// "Simpul" SASS / HTML & PHP website starting block that suits the majority of basic websites being built from scratch
// https://github.com/mrwigster/simpul/
// By @MrWigster
// Useful variables, brand colours, fonts, breaakpoint widths
@import "vars";
// The normalise styles by Bootstrap to normalize consistent styles across all browsers and set up some nice baseline standards
@import "simpul/norm";
// Media query setup
@import "simpul/mediaq";
// Useful little bits
@import "simpul/utils";
// A simple accordion
@import "simpul/accordion";
// A simple slider
@import "simpul/slider";
// Basic layout and framework structure
@import "simpul/framework";
// Basic polyfill to fall grid layout down to grid
@import "simpul/polyfill";
// Social Icons
@import "simpul/social";
// "hero" style element/large header
@import "simpul/hero";
// Form related elements.
@import "simpul/form";
// Button related elements.
@import "simpul/button";
// Header of site
@import "simpul/header";
// Footer of site
@import "simpul/footer";
// All baseline typography settings
@import "simpul/typography";
// Long form content baseline (based on lots of research that narrow long form is better)
@import "simpul/content";
// Simple card element
@import "simpul/card";
// Basic blog styles
// Home
.simpul-hero.home {
    background-image: url(img/atg-coffee-area.jpg);
    background-position: center;
    padding: 10rem 0;
    h1 {
        margin-bottom: 1rem;
        color: $brandSecondary;
    }
    h2 {
        line-height: 1.4;
        margin-top: 0.5rem;
    }
    @include lessThanTablet() {
        padding: 2rem 0;
    }
}

.shop-menu {
    background-image: url(img/artwork-bg.png);
    background-size: cover;
    padding: 5rem 0 3rem;
    h3 {
        color: white;
        text-align: center;
    }
    .food-cabinet {
        text-align: center;
        h4 {
            background-color: $brandSecondary;
            padding: 0.9rem 1rem;
            display: inline-block;
            width: auto;
            box-shadow: 3px 3px 0 2px $brandMain;
        }
        p {
            color: white;
        }
        img {
            max-width: 800px;
            margin: auto;
            border-radius: 10px;
            height: 250px;
            object-fit: cover;
            display: block;
        }
    }
    .grid-wrap {
        background: $brandGray;
        border-radius: 15px;
        box-shadow: 10px 10px 0 0 black;
        padding: 2rem;
        max-width: 868px;
        margin: 2rem auto 4rem;
    }
    h4,
    h5 {
        color: white;
    }
    .boxed-price {
        padding: 0.5rem 0.75rem;
        border: 1px dashed white;
        color: White;
        display: inline-block;
        margin: auto;
        margin-bottom: 1rem;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            display: flex;
            justify-content: space-between;
            color: white;
            margin-bottom: 0.33rem;
        }
    }
    .lined-price {
        position: relative;
        display: flex;
        justify-content: space-between;
        span {
            background: $brandGray;
            position: relative;
            z-index: 2;
            &:first-of-type {
                padding-right: 0.3rem;
            }
            &:last-of-type {
                padding-left: 0.3rem;
            }
        }
    }
    .lined-price:after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        // background: #e3e3e3;
        border-bottom: 1px dotted white;
        // margin: 0.5rem 0;
        position: absolute;
        z-index: 0;
        bottom: 3px;
    }
    .full {
        text-align: center;
        color: White;
    }
}

.gallery {
    background: $brandMain;
    padding: 5rem 0;
    .third {
        padding: 3rem;
        img {
            object-fit: cover;
            aspect-ratio: 1 / 1;
            width: 100%;
            box-shadow: 10px 10px 0 1px $brandSecondary;
        }
        @include lessThanTablet() {
            grid-column: span 6;
            padding: 1rem;
            img {
                box-shadow: 5px 5px 0 1px $brandSecondary;
            }
        }
    }
}

.seperator-section {
    width: 100%;
    height: 100px;
    display: flex;
    background-color: $brandGray;
    // background-color: #141a1f;
    height: 100px;
    width: 100%;
    border: 2px dashed #ae8c6d3b;
    border-width: 1px 0 1px 0;
    box-shadow: inset 0 0 20px 17px #0e131896;
}

.separator {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 100px;
    background: $brandSecondary;
    height: 1px;
    &.spacecreator {
        margin: 3rem auto;
    }
}

.didiv {
    position: relative;
    &.onwhite {
        &:before {
            background-color: white;
            border-color: white;
        }
    }
    &:before {
        --size: 45px;
        content: "";
        border: 6px solid $brandGray;
        height: var(--size);
        width: var(--size);
        left: 50%;
        margin: calc(var(--size) / 2 * -1) auto auto calc(var(--size) / 2 * -1);
        position: absolute;
        // transform: rotate(-45deg);
        background: $brandGray;
        background-image: url(img/icons/atg-icons-cog.png);
        background-size: contain;
    }
}

// about
.simpul-hero.about {
    background-image: url(img/photos/macaroons.jpg);
}

.atg-chris-frazer {
    box-shadow: 10px 10px 0px 0px $brandSecondary;
    border-radius: 2px;
    margin: 0 auto;
    @include lessThanDesktop() {
        max-width: 80%;
        margin-bottom: 2rem;
    }
}

.about-content {
    .flex-wrap {
        padding: 3rem 0;
        align-items: flex-start;
    }
    .twothird {
        padding-left: 2rem;
        @include lessThanTablet() {
            padding-left: 0;
        }
    }
    p {
        color: $brandGray;
    }
}

// contact
.simpul-hero.contact {
    background-image: url(img/photos/signage.jpg);
}

.contact-content {
    .flex-wrap {
        padding: 3rem 0;
    }
    .social-icons {
        img {
            filter: brightness(0.1);
            max-width: 43px;
            margin-right: 1rem;
        }
    }
}