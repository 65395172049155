$brandMain: #0e1317;
// brandMain-complement should contrast well against brandMain (Eg, text on buttons).
$brandMainComplement: #fdf3d8;
$brandSecondary: #c8a07a;
$brandSecondaryComplement: #ffffff;
$offWhite: #e3e3e3;
$offBlack: #464646;
$brandGray: #141a1f;
// Typography basics
$fontColor: #000000;
$contentFonts: "Playfair Display",
arial,
sans-serif;
$contentFontsWeight: 400;
$headingFonts: "Playfair Display",
arial,
sans-serif;
$headingFontsWeight: 700;
// Applied to body, sets the basis for all the em/rems on the site
$baseFontSize: 18px;
// Breakpoint we consider largest size for a mobile
$mobile-width: 475px;
// Minimum breakpoint to consider a tablet
$tablet-width: 768px;
// Minimum breakpoint to consider a desktop
$desktop-width: 1024px;
// For wide screen
$wide-width: 1480px;
// Sets the maximum width a container will go to, we don't want really long lines of text the complete width of a widescreen browser
$containedWidth: 1024px;
$constrainContainedWidth: 1024px;
// Basic link controls
$linkColor: $brandMain;
$linkHoverColor: $brandSecondary;
$linkDecoration: none;
$linkHoverDecoration: underline;
// Flex Layout:
// Gap/margin between our flex items
$flexGap: 18px;
// Grid Layout:
// Control the number of columns our grid has, it truly only really works with 12
$gridColumns: 12;
$gridGap: $flexGap;