.simpul-hero {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 4rem 0 5rem;
    position: relative;
    // min-height: 500px;
    background-color: $brandMain;
    @include mobile() {
        padding: 3em 0;
        // min-height: 40vh;
    }
    .hero-content {
        display: inline-block;
        width: 100%;
        @include mobile() {
            padding: 0.5em;
        }
        h1,
        h2,
        p {
            margin-top: 0;
            margin-bottom: 0.5em;
            color: white;
        }
        .btn {
            font-size: 1.5em;
            margin-top: 1em;
            margin-bottom: 0;
        }
    }
}

.inner-hero {
    padding: 3rem 0;
    min-height: auto;
    .hero-content {
        h1 {
            margin-bottom: 1rem;
            font-size: 2rem;
        }
        h2 {
            font-size: 1.25rem;
            margin-bottom: 1rem;
        }
    }
}

.background-media-shade {
    // background-color: transparent;
    &::after {
        content: "";
        background-color: rgba($brandMain, $alpha: 0.85);
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
        z-index: 0;
    }
    .container {
        position: relative;
        z-index: 2;
    }
}

.background-video {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: -1;
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}