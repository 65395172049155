.simpul-slider {
    min-height: 500px;
    min-height: 80vh;
    position: relative;
    overflow: hidden;
}

#simpul-slides {
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    transition: all 0.3s ease;
}

.simpul-slide-extras {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .left-panel,
    .right-panel {
        position: absolute;
        top: 0;
        width: 10%;
        height: 100%;
        z-index: 2;
    }
    .left-panel {
        cursor: w-resize;
        left: 0;
    }
    .right-panel {
        cursor: e-resize;
        right: 0;
    }
}

.simpul-slide-count {
    position: absolute;
    bottom: 1em;
    right: 1em;
    color: $brandMainComplement;
    z-index: 5;
}

.simpul-slide {
    background-size: cover;
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        background: rgba(0, 0, 0, 0.65);
    }
    .simpul-slide-content {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
    }
    .container {
        padding: 0 10%;
    }
    .container,
    .simpul-slide-content {
        z-index: 2;
        position: relative;
        height: 100%;
        color: $brandMainComplement;
    }
    p {
        width: 100%;
    }
    .btn {
        color: $brandMainComplement;
        border-color: $brandMainComplement;
        &:hover {
            background-color: $brandMain;
            color: $brandMainComplement;
            border-color: $brandMainComplement;
        }
    }
}