html {
    width: 100%;
}

body {
    background: white;
    position: relative;
    width: 100%;
}

.sub-header {
    background: $brandMain;
    padding: 0.8rem 0 1rem;
    border-top: 1px solid #c79f7a14;
    text-align: center;
    border-bottom: 2px solid black;
    box-shadow: 0px -1px 5px 0px #06090cb3;
    a {
        color: white;
        margin: 0rem 0.5rem;
        padding: 0 0.5rem;
        &:hover {
            color: $brandSecondary;
        }
        // text-transform: uppercase;
    }
}

header.site-header {
    padding: 0.5rem 0 1rem;
    width: 100%;
    z-index: 100;
    background: $brandMain;
    // border-bottom: 1px solid #e3e3e3;
    .flex-wrap {
        align-items: center;
        justify-content: space-between;
    }
    .header-third {
        display: flex;
        align-items: flex-start;
        width: 33.33%;
        color: $brandSecondary;
        flex-direction: column;
        @include lessThanTablet() {
            font-size: 0.8rem;
        }
        .open-time {
            display: block;
            width: 100%;
            text-align: right;
            @include lessThanTablet() {
                margin-bottom: 4px;
            }
        }
        h5 {
            margin-bottom: 0;
        }
    }
    .header-logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33.33%;
        // margin-left: $flexGap / 2;
        img,
        svg {
            display: block;
            height: 150px;
            width: auto !important;
            @include lessThanTablet() {
                height: auto;
            }
            // max-width: 100%;
        }
    }
    .header-third:last-of-type {
        justify-content: flex-end;
        text-align: right;
    }
    .menu {
        a {
            padding: 0 20px;
            &:last-of-type {
                padding-right: 0;
                margin-right: $flexGap / 2;
            }
        }
        @include lessThanTablet() {
            justify-content: center;
        }
    }
}

#hamburger-menu {
    z-index: 1000;
}

.mobile-toggle {
    display: flex;
    align-items: center;
    appearance: none;
    touch-action: manipulation;
    background-color: none;
    color: inherit;
    border: 0;
    cursor: pointer;
    display: inline-block;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    outline: none;
    padding: 8px 0;
    &__icon {
        width: 25px;
        height: 2px;
        background: $brandMain;
        display: block;
        transition: height 0.1s cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
        &::after,
        &::before {
            content: " ";
            width: 25px;
            height: 2px;
            background: $brandMain;
            display: block;
            position: relative;
            top: 6px;
            transition: top 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.3s, transform 0.3s cubic-bezier(0.19, 1, 0.22, 1);
        }
        &::before {
            top: -8px;
        }
    }
    &.active &__icon {
        height: 0;
        &::after {
            top: -2px;
            transform: rotate(45deg);
            transition: top 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0s, transform 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
        }
        &::before {
            top: 0;
            transform: rotate(-45deg);
            transition: top 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0s, transform 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0.3s;
        }
    }
}