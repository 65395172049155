footer.site-footer {
    background-color: $brandMain;
    color: $brandSecondary;
    // margin-top: 2rem;
    padding: 2rem 0;
    font-size: 1rem;
    a {
        display: block;
        width: 100%;
        color: $offWhite;
        margin: 0 0 0.8em;
        &:hover {
            color: white;
        }
        @include mobile() {
            padding: 0.5em 0;
        }
    }
    p {
        color: $offWhite;
        margin: 0;
        width: 100%;
    }
    h2 {
        font-size: 1.2rem;
        margin: 0 0 1rem 0;
        color: $offWhite;
    }
    .footer-section {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        a.logo {
            display: block;
            max-width: 200px;
            @include lessThanTablet() {
                margin: auto;
            }
        }
        @include lessThanTablet() {
            text-align: center;
        }
        &.where {
            text-align: center;
        }
        &.when {
            text-align: right;
            @include lessThanTablet() {
                text-align: center;
            }
        }
    }
    .newsletter {
        input {
            background: $brandMain;
            border: none;
            border-bottom: 1px solid $brandMainComplement;
            width: 100%;
            margin-top: 1em;
            padding: 0.5em 0;
            font-size: 1em;
            color: $brandMainComplement;
            &:focus {
                outline: none;
            }
        }
    }
    .copyright {
        width: 100%;
        font-size: 0.8rem;
        color: white;
        text-align: center;
        margin-top: 1rem;
        a {
            display: inline;
        }
        span {
            display: block;
            margin-top: 0.5rem;
        }
    }
    .social-icons {
        width: 100%;
    }
}